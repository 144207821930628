import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';

import "gatsby-theme-starberry-lomondgroup/src/components/Reviews/Reviews.scss";

import PlayVideo from "gatsby-theme-starberry-lomondgroup/src/modules/video_module/PlayVideo";
import ImageModule from "gatsby-theme-starberry-lomondgroup/src/modules/image_module";

const VideoReviews = ({GQLPage}) => {

    let [isPlay, setPlay] = React.useState(false);

  const CustomerVideoQuery = gql(`
    query CustomerVideoQuery($id: ID!) {
      page(id:$id) {
        add_components{
            ... on ComponentComponentsCustomerVideoReviews {
                id
                customer_video_review {
                  name
                  id
                  content
                  embed_video_url
                  designation
                  customer_icon {
                    alternativeText
                    url
                  }
                  image {
                    alternativeText
                    url
                  }
                }
            }
        }
      }
    }
 `)

  const { data, loading, error } = useQuery(CustomerVideoQuery, {
    variables: {
        id: GQLPage.id
    }
  });

  var GQlModule = data?.page?.add_components?.length > 0 && data?.page?.add_components.filter(item => item.__typename === "ComponentComponentsCustomerVideoReviews")

  var Module = GQlModule.length > 0 ? GQlModule[0]?.customer_video_review : [];

var videoUrl = (Module.length > 0 &&  Module[0]?.embed_video_url) ? Module[0]?.embed_video_url : "";

//console.log("videoUrl", videoUrl);


  return (
    <React.Fragment>
      {Module.length > 0 && <>
      <section className="reviews-section video_reviews">
        <Container>
          <Row>
            <Col md="12" lg="6" className="details-wrap order-md-2 order-xl-1">
              <p>{Module[0].content}</p>
              <div className="sm-info">
                {Module[0].customer_icon?.url && <ImageModule ImageSrc={Module[0].customer_icon} alt={Module[0].name}  loading="eager" classNames="sm-icon" /> }
                <div className="sm-info-content">
                  <h6>{Module[0].name}</h6>
                  {Module[0].designation && <span className="time">{Module[0].designation}</span>}
                </div>
              </div>
            </Col>
            <Col md="12" lg="6" className="order-md-1 order-xl-2">
              <div className="reviews-img img-zoom">
                {/* <img src={Module[0]} alt="ReviewsImg" /> */}
                <ImageModule ImageSrc={Module[0].image} alt={Module[0].name}  loading="eager" />

                {videoUrl && 
                    <button className="video-wrap" onClick={e => { setPlay(true) }}>
                        <i className="icon-video"></i>
                    </button>
                }
              </div>

            </Col>
          </Row>
        </Container>
      </section>

        
        <PlayVideo
            isOpen={isPlay}
            isCloseFunction={setPlay}
            videoUrl={videoUrl}
            isAutoPlay={1}
        />
        
    </>
    }
    </React.Fragment>
  );
};
export default VideoReviews;