import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";

import PlayVideo from "gatsby-theme-starberry-lomondgroup/src/modules/video_module/PlayVideo";
import "gatsby-theme-starberry-lomondgroup/src/components/Reviews/Reviews.scss";
import "./TeamThink.scss";

const TeamThink = ({ Module }) => {

  let [isPlay, setPlay] = React.useState(false);
  let [videoURl, setVideoUrl] = React.useState("");
  //console.log('Reviews', Module)

  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 1300,
            settings: {
                arrows: true,
                dots: false,
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 992,
            settings: {
                arrows: true,
                dots: true,
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 767,
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
  };

  return (
    <React.Fragment>
      <section className="reviews-section team_think">

        <h2>{Module?.title}</h2>
        <p>{Module?.content}</p>

        <Container>
          <Row>
            <Col>
            {Module?.video_reviews.length > 0 && 
              <Slider {...settings}>
                {Module?.video_reviews?.map((item) => {
                  return (

                    <div className="reviews-img img-zoom">
                      <img src={item?.Image?.url} alt="ReviewsImg" />
                      <div className="reviews-content">
                        <h3>{item.Name}</h3>
                        <span className="sm-info">{item.Description}</span>
                      </div>
                      {item.Video_URL &&
                        <button className="video-wrap" onClick={e => {
                          setVideoUrl(item.Video_URL)
                          setPlay(true)
                        }}>
                          <i className="icon-video"></i>
                        </button>
                      }
                    </div>

                  )
                })}
              </Slider>
            }
            </Col>
          </Row>
        </Container>
      </section>
      <PlayVideo
        isOpen={isPlay}
        isCloseFunction={setPlay}
        videoUrl={videoURl}
        isAutoPlay={1}
      />
    </React.Fragment>
  );
};
export default TeamThink;
